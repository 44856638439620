<template>
  <AppMainContainer
    center-class="offer-item"
    :show-modal="!!currentModal"
    @isScroll="(val) => {isScroll = val}"
    @closeModal="closeModal"
  >
    <template #subheader>
      <div id="worker-list-wrapper">
        <PageHeader
          v-if="!isOrganizationRoot && officeExists"
          :isAdmin="false"
        />
      </div>
    </template>
    <template #centerTop>
      <div style="margin-top: 16px; margin-bottom: 16px">
        <WorkerFilterList
          :filters="filters"
          @onFilterChange="onFilterChange"
        />
        <v-text-field
          v-model="querySearch"
          class="my-3"
          :placeholder="'Поиск работников по ФИО'"
          dense
          rounded
          :color="'success'"
          solo
          outlined
          hide-details="auto"
          clearable
          @input="onSearch"
        />
        <!--        <v-autocomplete-->
        <!--          v-model="searchModel"-->
        <!--          class="office-select mr-2"-->
        <!--          :items="getWorkerList"-->
        <!--          dense-->
        <!--          rounded-->
        <!--          solo-->
        <!--          :color="'success'"-->
        <!--          outlined-->
        <!--          :search-input.sync="querySearch"-->
        <!--          return-object-->
        <!--          :loading="false"-->
        <!--          background-color="#F0F0F1"-->
        <!--          item-value="id"-->
        <!--          item-text="fullName"-->
        <!--          :placeholder="'Поиск работников по ФИО'"-->
        <!--          :multiple="false"-->
        <!--          flat-->
        <!--          hide-no-data-->
        <!--          hide-details-->
        <!--        />-->
      </div>
    </template>
    <template #center>
      <div
        :style="chatStyle"
        class="offer-item"
      >
        <div class="offer-item__left">
          <div
            v-if="getWorkerList.length"
            class="offer-item__left__list-user"
          >
            <OfferItemChatUserCard
              v-for="worker in getWorkerList"
              :key="worker.id"
              :response-laborer="worker"
              :laborer="worker"
              :selected-user="selectedUser"
              :modelType="selectedStatus"
              :isLoading="isLoading"
              is-worker-list
              :worker-filter="filters.statusGroup"
              show-experience
              @click="selectLaborer"
              @accept="onSuccessButtonAction(worker)"
              @reject="onRejectButtonAction(worker)"
            />
          </div>
        </div>

        <div class="offer-item__right">
          <OfferItemChat
            :selected-user="selectedUser"
            :respondents="getWorkerList"
            :offer="offer"
            :selectedStatus="selectedStatus"
            :isLoading="isLoading"
            :worker-filter="filters.statusGroup"
            is-worker-list
            @accept="onSuccessButtonAction"
            @reject="onRejectButtonAction"
          />
        </div>
      </div>
    </template>
    <template #modal>
      <LaborerToBlackListModal
        v-if="getActiveLabel && currentModal === laborerManagerModal"
        :laborer="getActiveLabel"
        :planed-list="getActiveLabel.planedList"
        :title="currentContent.title"
        :label="currentContent.label"
        :success-button-title="currentContent.successButtonTitle"
        :required-text-area="currentContent.requiredTextArea"
        :is-loading="isLoading"
        :is-black-list="currentContent.type === blackListType"
        :reason.sync="currentContent.reason"
        @close="onModalClose"
        @onSave="onSave"
      />
    </template>
  </AppMainContainer>
</template>

<script>
import AppMainContainer from '@/components/common/layout/AppMainContainer'
import OfferItemChatUserCard from '@/components/offer/OfferItemChatUserCard'
import OfferItemChat from '@/components/offer/OfferItemChat'
import userUtil from '@/utils/user-util'
import WorkerFilterList, {
  WORKER_FILTER, FAVORITE_FILTER, BLACK_LIST_FILTER
} from '@/components/worker/workerFilterList'
import LaborerToBlackListModal from '@/components/worker/black-list-modal'
import PageHeader from '@/components/subheaders/PageHeader.vue'

const LABORER_MANAGER_MODAL = 'laborer-manager-modal'
const ADD_TO_BLACK_LIST_MODAL = 'laborer-black-list-add'
const REMOVE_FROM_BLACK_LIST_MODAL = 'laborer-black-list-remove'
const REMOVE_FROM_TEAM_MODAL = 'laborer-team-remove'
const ADD_TO_TEAM_MODAL = 'laborer-team-add'

const modalName = {
  cancelOffer: 'cancelOffer',
  acceptLaborer: 'acceptLaborer',
  rejectLaborer: 'rejectLaborer',
}

export default {
  name: 'PageOfferItem',
  components: {
    PageHeader,
    LaborerToBlackListModal,
    OfferItemChat,
    WorkerFilterList,
    OfferItemChatUserCard,
    AppMainContainer
  },
  props: {},
  data () {
    return {
      searchModel: null,
      blackListType: ADD_TO_BLACK_LIST_MODAL,
      laborerManagerModal: LABORER_MANAGER_MODAL,
      modalData: {
        ADD_TO_BLACK_LIST_MODAL,
        REMOVE_FROM_BLACK_LIST_MODAL,
        ADD_TO_TEAM_MODAL,
        REMOVE_FROM_TEAM_MODAL
      },
      currentContent: {
        type: null,
        title: null,
        description: null,
        reason: null,
        label: null,
        requiredTextArea: false,
        successButtonTitle: null,
      },
      modalContent: {
        [ADD_TO_BLACK_LIST_MODAL]: {
          type: ADD_TO_BLACK_LIST_MODAL,
          title: 'Работник будет добавлен в черный список',
          description: 'null',
          reason: 'null',
          requiredTextArea: true,
          label: 'Уточните причину почему вы хотите добавить работника в черный список',
          successButtonTitle: 'Добавить',
        },
        [REMOVE_FROM_BLACK_LIST_MODAL]: {
          type: REMOVE_FROM_BLACK_LIST_MODAL,
          title: 'Работник будет убран из черного списка',
          description: null,
          requiredTextArea: false,
          label: null,
          successButtonTitle: 'Убрать',
        },
        [ADD_TO_TEAM_MODAL]: {
          type: ADD_TO_TEAM_MODAL,
          title: 'Работник будет добавлен в Мою Команду',
          description: 'Он сможет сразу записываться на смену без вашего приглашения на смену',
          label: '',
          requiredTextArea: false,
          successButtonTitle: 'Добавить',
        },
        [REMOVE_FROM_TEAM_MODAL]: {
          type: REMOVE_FROM_TEAM_MODAL,
          title: 'Работник убран из моей команды',
          description: null,
          label: null,
          requiredTextArea: false,
          successButtonTitle: 'Убрать',
        },
      },
      filters: {
        statusGroup: WORKER_FILTER,
      },
      serverErrorDisplayTimeOut: 8000,
      querySearch: null,
      chatStyle: null,
      heightDiff: 240,
      errorCode: null,
      isLoading: false,
      cancelOfferNumber: 1,
      offer: {},
      selectedStatus: {},
      selectedUser: null,
      workerList: [],
      currentModal: '',
      modalName
    }
  },

  computed: {
    getActiveLabel () {
      if (this.selectedUser && this.selectedUser.laborer) {
        return this.selectedUser.laborer
      }
      return this.selectedUser
    },
    getWorkerList () {
      let list = this.workerList || []

      if (this.querySearch && this.querySearch.length > 1) {
        list = list.filter((item) => {
          return this.filterLaborerList(item, this.querySearch, '')
        })
      }


      switch (this.filters.statusGroup) {
      case FAVORITE_FILTER:
        return list.filter((w) => !!w.isFavorite && !w.isBlackListed)
      case BLACK_LIST_FILTER:
        return list.filter((w) => !w.isFavorite && !!w.isBlackListed)
      case WORKER_FILTER:
        return list.filter((w) => !w.isFavorite && !w.isBlackListed)
      }

      return []
    },
    isOrganizationRoot () {
      return userUtil.isRoot(this.currentUser) || userUtil.isAdmin(this.currentUser)
    },
    currentOffice () {
      return this.$store.state.office.userOffice
    },
    officeExists () {
      return !!this.$store.state.office.userOffice && !!this.$store.state.office.userOffice.id
    }
  },

  destroyed () {
  },
  created () {
    this.requestWorkerList()
  },
  beforeUpdate () {
    this.updateChatSize('worker-list-wrapper', this.heightDiff)
  },
  updated () {
    this.updateChatSize('worker-list-wrapper', this.heightDiff)
  },
  mounted () {
    this.$nextTick(function () {
      this.updateChatSize('worker-list-wrapper', this.heightDiff)
    })
  },
  methods: {
    filterLaborerList (item, queryText, itemText) {
      const searchText = queryText.toLowerCase()
      const fullName = item.fullName.toLowerCase()

      return fullName.indexOf(searchText) > -1
    },
    requestWorkerList () {
      this.$store.dispatch('office/getLaborerList', { })
        .then((response) => {
          this.workerList = response
        })
    },
    onModalClose () {
      this.currentModal = ''
    },
    onRejectButtonAction (laborer) {
      this.currentModal = this.laborerManagerModal
      this.selectedUser = laborer
      if (this.filters.statusGroup === WORKER_FILTER) {
        this.currentContent = this.modalContent[ADD_TO_BLACK_LIST_MODAL]
      } else if (this.filters.statusGroup === FAVORITE_FILTER) {
        this.currentContent = this.modalContent[REMOVE_FROM_TEAM_MODAL]
      }
    },
    onSuccessButtonAction (laborer) {
      this.currentModal = this.laborerManagerModal
      this.selectedUser = laborer
      if (this.filters.statusGroup === WORKER_FILTER) {
        this.currentContent = this.modalContent[ADD_TO_TEAM_MODAL]
      } else if (this.filters.statusGroup === BLACK_LIST_FILTER) {
        this.currentContent = this.modalContent[REMOVE_FROM_BLACK_LIST_MODAL]
      }
    },
    onSave () {
      this.isLoading = true
      switch (this.currentContent.type) {
      case ADD_TO_TEAM_MODAL:
        this.addLaborer()
        break
      case REMOVE_FROM_TEAM_MODAL:
        this.removeLaborer()
        break
      case ADD_TO_BLACK_LIST_MODAL:
        this.blockLaborer()
        break
      case REMOVE_FROM_BLACK_LIST_MODAL:
        this.unblockLaborer()
        break
      default:
        this.isLoading = false
        break
      }
    },
    addLaborer () {
      this.sendRequest('office/addLaborer', { laborerList: [{ id: this.getActiveLabel.id }] })
    },
    removeLaborer () {
      this.sendRequest('office/removeLaborer', { laborerList: [{ id: this.getActiveLabel.id }] })
    },
    blockLaborer () {
      if (this.currentContent.reason && this.currentContent.reason.length) {
        this.sendRequest(
          'office/blockLaborer',
          {
            laborerList: [{ id: this.getActiveLabel.id, description: this.currentContent.reason }]
          }
        )
      }
    },
    unblockLaborer () {
      this.sendRequest('office/unblockLaborer', { laborerList: [{ id: this.getActiveLabel.id }] })
    },
    sendRequest (type, payload) {
      try {
        this.$store.dispatch(type, payload)
          .then(async (response) => {
            await this.requestWorkerList()
            this.onModalClose()
            this.selectedUser = null
            this.isLoading = false
          }).catch((error) => {
            console.log(error)
            this.isLoading = false
          })
      } catch (err) {
        this.isLoading = false
        console.log(err)
      }
    },
    selectLaborer (laborer) {
      this.selectedUser = laborer
      this.$store.commit('offer/setCurrentOfferLaborer', laborer)
    },
    selectStatus (status) {
    },
    closeModal () {
      this.currentModal = ''
    },
    onSearchBlur (status) {
    },
    onFilterChange (filter) {
      this.closeModal()
      this.selectedUser = null
      this.filters = {
        statusGroup: filter,
      }
    },
    onSearch () {
    }
  }
}
</script>

<style lang="sass" scoped>
.cancel-description
  text-align: center
  @include fontStyle(500, 12px)
  opacity: .2
  margin-top: 10px

.offer-item
  padding-bottom: 0 !important

  &__left
    display: flex
    flex-direction: column
    width: 45%
    margin-right: 30px

    &__header__status
      display: flex
      margin: 15px 0

      &:first-of-type
        margin-top: 0

    &__list-user
      overflow: auto
      flex-grow: 1
      align-items: flex-start

      &_empty
        @include fontStyle(500, 16px)
        margin: 40px auto 0 auto
        color: rgba(0, 0, 0, 0.51)

  &__right
    width: 55%
    background-color: white
    height: 100%


</style>
