<template>
  <AppModalMiddle class="p-5">
    <h1>
      {{ title }}
    </h1>
    <div class="d-flex flex-column">
      <h2
        class="my-3"
      >
        {{ laborer.fullName }}
      </h2>
      <p
        v-if="label"
        class="textAreaLabel my-3"
      >
        {{ label }}
      </p>
      <div
        v-if="isBlackList && planedList && planedList.length"
        class="my-3"
      >
        <p
          v-if="description"
        >
          {{ description }}
        </p>
        <p
          class="textAreaLabel my-3"
          style="color: red"
        >
          {{
            `У работника есть будущие смены на вашем объекте:`
          }}
        </p>
        <p
          v-for="planedRelay in planedList"
          :key="planedRelay.id"
          class="textAreaLabel"
          style="color: red; margin: 0 !important; padding: 0!important;"
        >
          {{
            `${ formatDate(planedRelay.startAt) } - ${planedRelay.vacancy}`
          }}
        </p>
        <p
          class="textAreaLabel my-1"
        >
          {{ `Они будут отменены!` }}
        </p>
      </div>
      <textarea
        v-if="requiredTextArea"
        v-model="request.description"
        :max="5000"
        class="app-textarea mb-2"
        placeholder="Уточните причину почему вы хотите добавить работника в черный список"
        @change="$emit('update:reason', request.description)"
      />
      <div
        v-if="isLoading"
        class="modal__button"
        style="width: 100%; text-align: right"
      >
        <AppLoader
          style="margin: 10px"
        />
      </div>
      <div
        v-else
        class="modal__button"
        style="width: 100%; text-align: right"
      >
        <AppButton
          style="align-self: flex-end"
          class="button-text-simple"
          @click.native="onClose"
        >
          Отмена
        </AppButton>
        <AppButton
          :disabled="requiredTextArea && (!request.description || !request.description.length)"
          @click.native="onSave"
        >
          {{ successButtonTitle }}
        </AppButton>
      </div>
    </div>
  </AppModalMiddle>
</template>

<script>
import AppModalMiddle from '@/components/common/modal/AppModalMiddle'
import AppButton from '@/components/common/simple/AppButton'
import AppLoader from '@/components/AppLoader'

export default {
  name: 'LaborerToBlackListModal',
  components: {
    AppModalMiddle,
    AppLoader,
    AppButton,
  },
  props: {
    laborer: {
      type: Object
    },
    label: {
      type: String
    },
    title: {
      type: String,
      required: true
    },
    successButtonTitle: {
      type: String,
      required: true
    },
    isBlackList: {
      type: Boolean,
      default: false,
      required: false
    },
    requiredTextArea: {
      type: Boolean,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    },
    planedList: {
      type: Array,
      default: () => null
    },
    description: {
      type: String,
    },
    reason: {
      type: String,
    },
  },
  data: () => ({
    loader: false,
    request: {
      worker: {
        id: null,
      },
      description: '',
    }
  }),
  beforeDestroy () {
    this.onClose()
  },
  methods: {
    formatDate (date, format = 'DD MMMM HH:mm') {
      return this.$moment(date).format(format)
    },
    async onSave () {
      this.$emit('onSave')
    },
    onClose () {
      this.request = {
        worker: {
          id: null,
        },
        description: '',
      }
      this.$emit('close')
    }
  }
}
</script>
<style type="text/scss" scoped>
 .textAreaLabel
  {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;

    color: #000000;
  }
</style>
